<template>
  <div>
    <div
      v-if="dataLoaded && show404 === false"
      class="usa-layout-docs usa-section"
    >
      <div class="grid-container">
        <div class="grid-row grid-gap">
          <document-renderer
            :documentId="pageData.fields.leftMenuRef.sys.id" v-if="!hideSideNav"
          ></document-renderer>
          <div class="usa-layout-docs__main desktop:grid-col-9 usa-prose" :class="hideSideNav ? 'desktop:grid-col-12':''">
            <rich-text-renderer
              :data="pageData.fields.pageBody"
            ></rich-text-renderer>
            <div>
              <div
                class="usa-link cgi-link-next block-link"
                v-if="pageData.fields.backUrl"
              >
                <svg width="24" height="24" viewBox="0 0 192 512" role="img" focusable="false" aria-labelledby="svgTitle" aria-describedby="svgDescription">
                  <title id="svgTitle">Back</title>
                  <desc id="svgDescription">Back</desc>
                  <path
                    d="M4.2 247.5L151 99.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17L69.3 256l118.5 119.7c4.7 4.7 4.7 12.3 0 17L168 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 264.5c-4.7-4.7-4.7-12.3 0-17z"
                    fill="#0071bc"
                  ></path>
                </svg>
                <rich-text-renderer
                  :data="pageData.fields.backUrl"
                ></rich-text-renderer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PageNotFound v-if="dataLoaded && show404 === true" />
    <div :hidden="!showModal" v-if="pageData.fields.modalPopupRefs" >
      <document-renderer :key="i" v-for="(gp, i) of pageData.fields.modalPopupRefs" :documentId="gp.sys.id" :additionaldata="{
          calculator: calculator,
          showModal: showModal 
        }">
      </document-renderer>
    </div>
  </div>
</template>

<script>
import { 
  getCountryResourceGroup,
  getSystemGlobalResources,
  getUSTDLiveUrlStatus,
  getDataByContentType
} from "@/store/api";
import { urlSlug } from "@/helpers/url";
import PageNotFound from "@/components/PageNotFound";
function outsideMenuClickHandler(event) {
  const isButtonClicked = event.target.closest(".quick-notice"); 
  const isMegaMenuClicked = event.target.closest("#group-fees-dialog"); 
  if (!isButtonClicked && !isMegaMenuClicked) {
    this.permanentHide = 'permanentHide';
  }
}
export default {
  name: "left-menu-and-body-page-template",
  components: { PageNotFound },
  data() {
    return {
      dataLoaded: false,
      pageData: {},
      show404: false,      
      showModalClass: "",
      host: location.host,
      showModal: true,
      calculator: this,
      quickNotice: false,
      pageData:{
          fields: {
            modalPopupRefs: null
          }
      }
    };
  },
  created() {
    const urlSlugText = urlSlug();
    getDataByContentType("pageTemplateLeftMenuAndBody", urlSlugText, this.$route.params.countrycode, this.$route.params.langcode).then((pageData) => {
      this.dataLoaded = true;
      if (!pageData) {
        this.show404 = true; 
      }

      // Avnish, 04-Sept-2023, Requirement - check and show the country is live or not.
      getCountryResourceGroup(this.$route.params.countrycode).then((res) => {
        if(res?.fields?.resJson) {
          let globalResourceNotLiveArr = res.fields.resJson['non-live-countries'];
          // globalResourceNotLiveArr = [{"country": "de","language": "en","isNonLive": "true" }];
          console.log("globalResourceNotLiveArr:",globalResourceNotLiveArr);
          console.log("route Country and Lang Code:: ", this.$route.params.countrycode, this.$route.params.langcode)
        if((globalResourceNotLiveArr) && (this.host !='cgistaging.scriptbytes.co.in')){
            let filterCurrentCountryObj = globalResourceNotLiveArr.filter(el => el.country == this.$route.params.countrycode.trim() && ((el.language == this.$route.params.langcode.trim() && (el.isNonLive == 'true' || el.isNonLive == true)) || (!el.language && (el.isNonLive == 'true' || el.isNonLive == true))));
            console.log("filterCurrentCountryObj:", filterCurrentCountryObj);
            if(filterCurrentCountryObj.length > 0) {
              if(filterCurrentCountryObj[0]['isNonLive'] == "true" && this.$route.query.accesstoken && this.$route.query.accesstoken == filterCurrentCountryObj[0]['accesstoken']) {
                console.log("country level token wise accesible for non live country.", this.$route.query.accesstoken);
              }
              else {
                getSystemGlobalResources().then((globalResponse) => {
                  let globalAccessToken = globalResponse?.fields?.resJson?.accesstoken;
                  // globalAccessToken = "20ca9a431341756398632ada6b761820";
                  if(this.$route.query.accesstoken && globalAccessToken == this.$route.query.accesstoken) {
                    console.log("global level token wise accesible for non live country.", this.$route.query.accesstoken);
                  }
                  else {
                    getUSTDLiveUrlStatus(window.location.origin).then((liveUrlStatus) => {
                      console.log("liveUrlStatus:", liveUrlStatus);
                      if(!liveUrlStatus) {
                        this.show404 = true;
                      }
                    });
                  }
                });
              }
            }
          }
        }
        // this.pageData = pageData;
        // this.setPageTitle(this.pageData.fields.pageTitle || "USTravelDocs");
      });

      this.pageData = pageData;
      this.setPageTitle(this.pageData.fields.pageTitle || "USTravelDocs");
    });
    
    this.$on("close-modal", () => {
      this.showModal = false;
      this.quickNotice = true;
    });
    if(location.search.includes('nodialog')){
      this.showModal = false;
      this.quickNotice = false;
    }
  },
  computed: { 
    hideSideNav(){
      const urlSlugText =  urlSlug();
      if(urlSlugText == 'privacy-policy') return "display-none";
      else return "";
    }
  },
   mounted() {
      this.$root.$on("modal-popup:show", () =>{
      this.showModal = true;
      this.quickNotice = false;
    });
  },
  methods: {
    outsideMenuClickHandler: outsideMenuClickHandler,
    showQuickNotice() {
      this.quickNotice = false;
      this.showModal = true;
      this.$nextTick(() => {
        this.$el.querySelector('.usa-dialog__close').focus();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.display-none {
  display: none;
}
.page-header {
  padding: 2rem 0 3rem 0;
  background-color: #205493;

  ::deep .content {
    max-width: 40rem;
    padding: 0 2rem;
    h1 {
      font-family: Helvetica, Merriweather_Web, Georgia, Cambria, Times New Roman, Times,
        serif;
      color: white;
      font-size: 2.9rem;
    }
    p {
      font-family: Helvetica, Helvetica Neue, Helvetica, Roboto, Arial,
        sans-serif;
      color: white;
      line-height: 1.5;
    }
  }
}
::v-deep .usa-prose {
  h1 {
    font-family: "Helvetica", sans-serif;
    line-height: 1.22983;
    font-weight: 700;
    font-size: 2.43935rem;
  }
  p {
    line-height: 1.52155;
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.06471rem;
  }
  h2 {
    margin-top: 1em;
    font-size: 1.95148rem;
    line-height: 1.22983;
  }
  h3 {
    margin-top: 1em;
    margin-bottom: .5em;
    line-height: 1.22983;
    font-size: 1.34164rem;
  }
  h4 {
    font-size: 1.13125rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 1.13125rem;
  }
}
::v-deep ul {
  padding: 0;
  padding-left: 25px;
  li {
    margin-bottom: 0.25em;
    font-size: 1.06471rem;
    p {
      margin: 0 !important;
    }
  }
}
::v-deep ol {
  padding: 0;
  padding-left: 25px;
  li {
    line-height: 2;
    p {
      margin: 0 !important;
    }
  }
}
.usa-layout-docs {
  border-top: 1px solid #dcdee0;
}
.block-link {
  display: flex;
  align-items: center;
}
.usa-link {
  ::v-deep p {
    margin: 0;
  }
}
.showModal {
  display: block !important;
}
</style>